import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum RegisterError {
  None,
  TakenEmailOrUsername,
  Other,
}

interface RegisterModalState {
  error: RegisterError;
}

const initialState: RegisterModalState = {
  error: RegisterError.None,
};

export const registerModalSlice = createSlice({
  name: 'registerModal',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<RegisterError>) => {
      state.error = action.payload;
    },
  },
});

export const { setError } = registerModalSlice.actions;

export default registerModalSlice.reducer;
