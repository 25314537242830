import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAxiosAuthConfig } from '../utils';

const withProductsComparator = (WrappedComponent: any) => {
  return (props: any) => {
    const [data, setData] = useState([]);
    const [products, setProducts] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const config = useAxiosAuthConfig();
    const qs = require('qs');
    const query = qs.stringify(
      {
        filters: {
          id: {
            $in: products,
          },
        },
        populate: ['images', 'category', 'localizations'],
      },
      {
        encodeValuesOnly: true,
      }
    );
    useEffect(() => {
      if (products.length > 0) {
        setLoaded(false);
        axios
          .get(`/base-products?${query}`, config)
          .then((response) => {
            setData(response.data.data);
            setLoaded(true);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        setData([]);
      }
    }, [query, config, products.length]);
    return (
      <WrappedComponent
        comparatorProducts={data}
        setProducts={setProducts}
        productsLoaded={loaded}
        {...props}
      />
    );
  };
};
export default withProductsComparator;
