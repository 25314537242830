import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

const withTranslation =
  (
    name: string | Array<string>,
    endpoint: string = 'translations',
    getCustomLocale: Function = () => null
  ) =>
  (WrappedComponent: any) => {
    return (props: any) => {
      const [translation, setData] = useState({});
      const [loaded, setLoaded] = useState(false);
      const { i18n } = useTranslation();
      const customLocale =
        typeof getCustomLocale === 'function' ? getCustomLocale(props) : null;

      const localeShortcut = customLocale || i18n?.language?.slice(0, 2);

      useEffect(() => {
        if (Object.keys(translation).length > 0) {
          setLoaded(true);
        }
      }, [translation]);
      const query = qs.stringify(
        {
          filters: { name: { $contains: name } },
          locale: localeShortcut,
        },
        {
          encodeValuesOnly: true,
        }
      );
      useEffect(() => {
        if (localeShortcut) {
          axios
            .get(`/${endpoint}?${query}`)
            .then((response) => {
              setData(
                response.data.data.length > 1
                  ? response.data.data.sort((a: any, b: any) =>
                      a.name.localeCompare(b.name)
                    )
                  : response.data.data[0].data
              );
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }, [localeShortcut, query]);
      return (
        <WrappedComponent
          translation={translation}
          translationLoaded={loaded}
          {...props}
        />
      );
    };
  };
export default withTranslation;
