import s from './SloganBar.module.scss';

const SloganBar = (props: any) => {
  return (
    <div className={s.sloganBar}>
      <h4>{props.slogan}</h4>
    </div>
  );
};
export default SloganBar;
