import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Check if there's a stored language in localStorage
const storedLang = localStorage.getItem('i18nextLng')?.slice(0, 2) || 'en'; // Default to 'en' if no stored language

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    react: {
      useSuspense: false,
    },
    detection: {
      // Set the language detection priority
      order: ['localStorage', 'navigator', 'path', 'htmlTag'],
      lookupFromPathIndex: 0, // Detect language from the URL's first part (e.g., /en/ or /pl/)

      // This ensures if the language is detected from the URL, it’s stored in localStorage
      caches: ['localStorage'],
    },
    lng: storedLang, // Set the initial language based on localStorage or default to 'en'
    fallbackLng: 'en', // Fallback to English if the language can't be detected
    supportedLngs: ['en', 'pl'], // Supported languages: English and Polish
    ns: ['common'], // Namespaces if you have separate translation files
    preload: ['en', 'pl'],
  });

export default i18n;
