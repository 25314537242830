import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum LoginError {
  None,
  EmailNotConfirmed,
  Other,
}

interface LoginModalState {
  error: LoginError;
}

const initialState: LoginModalState = {
  error: LoginError.None,
};

export const loginModalSlice = createSlice({
  name: 'loginModal',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<LoginError>) => {
      state.error = action.payload;
    },
  },
});

export const { setError } = loginModalSlice.actions;

export default loginModalSlice.reducer;
