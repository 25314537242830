import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { Modal } from './redux/slices/modalSlice';
import { Notification } from './redux/slices/notificationSlice';
import { logOut } from './redux/slices/userSlice';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Type } from './hoc/withWishlistDraft';

export type Language = 'en' | 'pl';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}

export const useValidableState = (
  initialState: any,
  validationFunction: (state: any) => boolean = (state) => state?.length > 0,
  dependencyArray: Array<any> = []
) => {
  const [state, setState] = useState(initialState);
  const [valid, setValid] = useState(false);
  useEffect(() => {
    setValid(validationFunction(state));
    // eslint-disable-next-line
  }, [...dependencyArray, state, setValid, validationFunction]);
  return [state, setState, valid];
};

export const useBodyOverflow = () => {
  const scrollbarWidth = useScrollbarWidth();
  const modal: Modal = useAppSelector((state) => state.modal.modal);
  const burgerOpened: boolean = useAppSelector(
    (state) => state.header.burgerOpened
  );
  const notification: Notification = useAppSelector(
    (state) => state.notification.notification
  );
  useEffect(() => {
    if (
      modal !== Modal.None ||
      notification !== Notification.None ||
      burgerOpened
    ) {
      document.body.style.overflow = 'hidden';
      document.body.style.marginRight = `${scrollbarWidth}px`;
    } else {
      setTimeout(() => {
        document.body.style.overflow = '';
        document.body.style.marginRight = '';
      }, 300);
    }
  }, [modal, notification, burgerOpened, scrollbarWidth]);
};

export const useUserSession = () => {
  const rememberMe = useAppSelector((state) => state?.user?.rememberMe);
  const cookies = useCookies(['session'])[0];
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!rememberMe && !cookies.session) {
      dispatch(logOut());
    }
    // eslint-disable-next-line
  }, []);
};

export const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

/*
Password rules
  - 1 upper case letter
  - 1 lower case letter
  - 1 number
  - 1 special character
  - At least 8 characters long
*/

export const validatePassword = (password: string) =>
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!#$%&*?@^{}[\]().,<>`~:;|/\\_+-]).{8,}$/.test(
    password
  );


export const axiosDefaults = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
};

export const useAxiosAuthConfig = () => {
  const jwt = useAppSelector((state) => state?.user?.userData?.jwt);
  const [config, setConfig] = useState({});
  useMemo(() => {
    setConfig({
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  }, [jwt]);
  return config;
};

export const addIndexesToArray = (arr: any) => {
  let result = [];
  for (let index in arr) {
    result.push({ index, ...arr[index] });
  }
  return result;
};

export function splitArrayIntoChunksOfLen(arr: any, len: number) {
  let chunks = [],
    i = 0,
    n = arr?.length;
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
}

export const getProductInfo = (text: any, style = 'body-1', currency = '€') => {
  if (!text) return '';
  let variants: JSX.Element[] = [];
  for (let variantIndex in text) {
    let variant = text[variantIndex];
    if (variant.selected || variant.priceChangeType === 'default') {
      variants.push(
        variant?.priceChangeType === 'decrease' && variant?.price ? (
          <div
            key={variantIndex}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              columnGap: '2em',
            }}
          >
            <span
              className={style}
            >{`${variant.counter} x ${variant.label}`}</span>
            <span
              style={{ whiteSpace: 'nowrap' }}
              key={variantIndex}
              className={style}
            >
              {'- ' + currency + formatPrice(variant.price * variant.counter)}
            </span>
          </div>
        ) : variant?.priceChangeType === 'increase' && variant?.price ? (
          <div
            key={variantIndex}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              columnGap: '2em',
            }}
          >
            <span
              className={style}
            >{`${variant.counter} x ${variant.label}`}</span>
            <span
              style={{ whiteSpace: 'nowrap' }}
              key={variantIndex}
              className={style}
            >
              {'+ ' + currency + formatPrice(variant.price * variant.counter)}
            </span>
          </div>
        ) : variant?.price !== null && variant?.price !== 0 ? (
          <div
            key={variantIndex}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              columnGap: '2em',
            }}
          >
            <span
              className={style}
            >{`${variant.counter} x ${variant.label}`}</span>
            <span
              style={{ whiteSpace: 'nowrap' }}
              key={variantIndex}
              className={style}
            >
              {'+ ' + currency + formatPrice(variant?.price * variant.counter)}
            </span>
          </div>
        ) : (
          <span
            style={{ textAlign: 'left' }}
            key={variantIndex}
            className={style}
          >{`${variant.counter} x ${variant.label}`}</span>
        )
      );
    }
  }
  return variants;
};

export const getProductData = (product: any) => {
  return {
    cpu: product.cpu,
    gpu: product.gpu,
    ram: product.ram,
    osDrive: product.osDrive,
    os: product.os,
    service: product.service,
    additionalHdd: product.additionalHdd,
    accessory: product.accessory,
    pciExpress: product.pciExpress,
    quantity: product.quantity,
    settings: product.settings,
    product: product.product.id,
  };
};

export const sumProductAndExtrasPrices = (products: any) => {
  let arr = [];

  for (let index in products) {
    let sum = 0;
    const productEntry = products[index];
    const productData = productEntry.product;

    Object.values(productEntry.cpu).forEach((obj: any) => {
      const quantity =
        productEntry.settings.cpu.filter((itm: any) => itm.id === obj.id)[0]
          .counter ?? 1;
      if (obj.priceChangeType.name === 'increase') {
        sum = sum + obj.price * quantity;
      } else if (obj.priceChangeType.name === 'decrease') {
        sum = sum - obj.price * quantity;
      }
    });

    Object.values(productEntry.ram).forEach((obj: any) => {
      const quantity =
        productEntry.settings.ram.filter((itm: any) => itm.id === obj.id)[0]
          .counter ?? 1;
      if (obj.priceChangeType.name === 'increase') {
        sum = sum + obj.price * quantity;
      } else if (obj.priceChangeType.name === 'decrease') {
        sum = sum - obj.price * quantity;
      }
    });

    Object.values(productEntry.gpu).forEach((obj: any) => {
      const quantity =
        productEntry.settings.gpu.filter((itm: any) => itm.id === obj.id)[0]
          .counter ?? 1;
      if (obj.priceChangeType.name === 'increase') {
        sum = sum + obj.price * quantity;
      } else if (obj.priceChangeType.name === 'decrease') {
        sum = sum - obj.price * quantity;
      }
    });

    Object.values(productEntry.osDrive).forEach((obj: any) => {
      const quantity =
        productEntry.settings.osDrive.filter((itm: any) => itm.id === obj.id)[0]
          .counter ?? 1;
      if (obj.priceChangeType.name === 'increase') {
        sum = sum + obj.price * quantity;
      } else if (obj.priceChangeType.name === 'decrease') {
        sum = sum - obj.price * quantity;
      }
    });

    Object.values(productEntry.os).forEach((obj: any) => {
      const quantity =
        productEntry.settings.os.filter((itm: any) => itm.id === obj.id)[0]
          .counter ?? 1;
      if (obj.priceChangeType.name === 'increase') {
        sum = sum + obj.price * quantity;
      } else if (obj.priceChangeType.name === 'decrease') {
        sum = sum - obj.price * quantity;
      }
    });

    Object.values(productEntry.service).forEach((obj: any) => {
      const quantity =
        productEntry.settings.service.filter(
          (itm: any) => itm.id === obj.id
        )?.[0]?.counter ?? 1;
      sum = sum + obj.price * quantity;
    });
    Object.values(productEntry.additionalHdd).forEach((obj: any) => {
      const quantity =
        productEntry.settings.additionalHdd.filter(
          (itm: any) => itm.id === obj.id
        )[0].counter ?? 1;
      sum = sum + obj.price * quantity;
    });
    Object.values(productEntry.accessory).forEach((obj: any) => {
      const quantity =
        productEntry.settings.accessory.filter(
          (itm: any) => itm.id === obj.id
        )[0].counter ?? 1;
      sum = sum + obj.price * quantity;
    });
    Object.values(productEntry.pciExpress1x).forEach((obj: any) => {
      const quantity =
        productEntry.settings.pciExpress1x.filter(
          (itm: any) => itm.id === obj.id
        )[0].counter ?? 1;
      if (obj.priceChangeType.name === 'increase') {
        sum = sum + obj.price * quantity;
      } else if (obj.priceChangeType.name === 'decrease') {
        sum = sum - obj.price * quantity;
      }
    });
    Object.values(productEntry.pciExpress2x).forEach((obj: any) => {
      const quantity =
        productEntry.settings.pciExpress2x.filter(
          (itm: any) => itm.id === obj.id
        )[0].counter ?? 1;
      if (obj.priceChangeType.name === 'increase') {
        sum = sum + obj.price * quantity;
      } else if (obj.priceChangeType.name === 'decrease') {
        sum = sum - obj.price * quantity;
      }
    });
    Object.values(productEntry.pciExpress4x).forEach((obj: any) => {
      const quantity =
        productEntry.settings.pciExpress4x.filter(
          (itm: any) => itm.id === obj.id
        )[0].counter ?? 1;
      if (obj.priceChangeType.name === 'increase') {
        sum = sum + obj.price * quantity;
      } else if (obj.priceChangeType.name === 'decrease') {
        sum = sum - obj.price * quantity;
      }
    });
    Object.values(productEntry.pciExpress8x).forEach((obj: any) => {
      const quantity =
        productEntry.settings.pciExpress8x.filter(
          (itm: any) => itm.id === obj.id
        )[0].counter ?? 1;
      if (obj.priceChangeType.name === 'increase') {
        sum = sum + obj.price * quantity;
      } else if (obj.priceChangeType.name === 'decrease') {
        sum = sum - obj.price * quantity;
      }
    });
    Object.values(productEntry.pciExpress16x).forEach((obj: any) => {
      const quantity =
        productEntry.settings.pciExpress16x.filter(
          (itm: any) => itm.id === obj.id
        )[0].counter ?? 1;
      if (obj.priceChangeType.name === 'increase') {
        sum = sum + obj.price * quantity;
      } else if (obj.priceChangeType.name === 'decrease') {
        sum = sum - obj.price * quantity;
      }
    });
    sum = (sum + +productData.price) * productEntry.quantity;
    arr.push(sum);
  }
  return arr;
};

export const updateAllAddonsWithNewFields = (product: any, extras: any) => {
  let res: any = {};
  for (const [key, value] of Object.entries(extras)) {
    const obj = updateAddonWithNewFields(value, key, product);
    res[key] = obj;
  }
  return res;
};

export const updateAddonWithNewFields = (
  addon: any,
  addonName: string,
  product: any
) => {
  const newAddon = JSON.parse(JSON.stringify(addon));
  newAddon.forEach((addon: any) => {
    const match = product[addonName].find((elem: any) => elem.id === addon.id);
    if (match) {
      addon.counter = match?.counter;
      addon.selected = true;
    }
  });
  return newAddon;
};

export const fetchProductData = async (
  productId: number,
  userId: number,
  config: any
) => {
  const qs = require('qs');
  const q = qs.stringify(
    {
      filters: {
        id: {
          $eq: productId,
        },
      },
      populate: 'deep,3',
      id: userId,
    },
    {
      encodeValuesOnly: true,
    }
  );
  return (await axios.get(`/base-products?${q}`, config)).data.data[0];
};

export const fetchBlobData = async (url: string, config: any) => {
  const res = await axios.get(process.env.REACT_APP_HOST_URL + url, {
    ...config,
    responseType: 'blob',
  });
  return res.data;
};

export const prepareSingleChoiceItem = (
  item: any,
  selected: boolean | undefined = false,
  counter: number = 1
) => {
  if (item) {
    const obj: Service[] = [
      {
        id: item?.id,
        label: item?.mpn,
        selected: selected,
        description: item?.description,
        counter: counter,
        price: item?.price,
        priceChangeType: item?.priceChangeType?.name,
      },
    ];
    return obj;
  } else {
    return [];
  }
};

interface Service {
  id: number;
  label: string;
  selected: boolean | undefined;
  description: string;
  counter: number;
  price: number;
  priceChangeType?: string;
}

export const prepareMultiChoiceItem = (
  item: any,
  selected: boolean = false,
  counter: number = 1
) => {
  const obj: Service = {
    id: item?.id,
    label: item?.description ?? item?.mpn,
    selected: selected,
    description: item?.description,
    counter: counter,
    price: item?.price,
    priceChangeType: item?.priceChangeType?.name,
  };
  return obj;
};

export enum ExtrasType {
  cpu = 0,
  ram = 1,
  gpu = 2,
  osDrive = 3,
  os = 4,
  pciExpress1x = 5,
  pciExpress2x = 6,
  pciExpress4x = 7,
  pciExpress8x = 8,
  pciExpress16x = 9,
  additionalHdd = 10,
  service = 11,
  accessory = 12,
}

export const sumAddonsPrice = (product: any) => {
  let tot = 0;
  Object.keys(product).forEach((v: any, key: number) => {
    if (key >= 0 && key <= ExtrasType.pciExpress16x) {
      Object.values(product[key].text).forEach((obj: any) => {
        if (obj.selected) {
          if (obj.priceChangeType === 'increase') {
            tot = tot + obj.price * obj.counter;
          } else if (obj.priceChangeType === 'decrease') {
            tot = tot - obj.price * obj.counter;
          }
        }
      });
    } else if (
      key === ExtrasType.additionalHdd ||
      key === ExtrasType.service ||
      key === ExtrasType.accessory
    ) {
      Object.values(product[key].text).forEach((obj: any) => {
        if (obj.selected) {
          tot = tot + obj.price * obj.counter;
        }
      });
    }
  });
  return +tot.toFixed(2);
};

export const prepareProductSummary = (product: any) => {
  const mainPart = (name: string, key: string) => {
    return {
      name: name,
      text: product?.product?.[key].map((item: any) => {
        const match = product.settings[key].find(
          (setting: any) => setting.id === item.id
        );
        return prepareMultiChoiceItem(
          item,
          match || item?.priceChangeType?.name === 'default',
          match?.counter
        );
      }),
    };
  };
  const addonPart = (name: string, key: string) => {
    return {
      name: name,
      text: product?.[key].map((item: any) =>
        prepareMultiChoiceItem(
          item,
          true,
          product.settings[key].find((setting: any) => setting.id === item.id)
            ?.counter
        )
      ),
    };
  };
  return [
    mainPart('CPU', 'cpu'),
    mainPart('RAM', 'ram'),
    mainPart('GPU', 'gpu'),
    mainPart('OS DRIVE', 'osDrive'),
    mainPart('OS', 'os'),
    addonPart('PCI EXPRESS 1X', 'pciExpress1x'),
    addonPart('PCI EXPRESS 2X', 'pciExpress2x'),
    addonPart('PCI EXPRESS 4X', 'pciExpress4x'),
    addonPart('PCI EXPRESS 8X', 'pciExpress8x'),
    addonPart('PCI EXPRESS 16X', 'pciExpress16x'),
    addonPart('ADDITIONAL HDD', 'additionalHdd'),
    addonPart('SERVICES', 'service'),
    addonPart('ACCESSORY', 'accessory'),
  ];
};

export const getWhere = (where: Type) => {
  switch (where) {
    case Type.Draft:
      return 'Draft';
    case Type.Cart:
      return 'Cart';
    case Type.Quotation:
      return 'Quotation';
    case Type.InProgress:
      return 'In Progress';
  }
};

export const formatPrice = (price: number | string) => {
  const formatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
  });
  const formattedPrice = formatter.format(Number(price));
  return formattedPrice;
};

export const addPriceToProduct = (product: any) => {
  const props = [
    'additionalHdd',
    'pciExpress1x',
    'pciExpress2x',
    'pciExpress4x',
    'pciExpress8x',
    'pciExpress16x',
    'service',
    'accessory',
    'cpu',
    'gpu',
    'ram',
    'os',
    'osDrive',
  ];
  props.map((prop: string) => {
    return product[prop].map((obj: any) => {
      return (obj.price = product.product[prop].find(
        (item: any) => item.id === obj.id
      ).price);
    });
  });
};

export const downloadBlob = (blob: any, name: string) => {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = name;
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
  document.body.removeChild(link);
};

export const csvMaker = (data: any, headerNames?: any) => {
  const DELIMITER = ',';
  const escape = (x: string) => x.replaceAll('"', '""');
  const enquote = (x: any) => `"${escape(x?.toString() ?? '')}"`;

  let csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(
    headers
      .map((x) => enquote(headerNames ? headerNames[x] : x))
      .join(DELIMITER)
  );
  data.forEach((product: Array<string>) => {
    const values = Object.values(product)
      .map((x) => enquote(x))
      .join(DELIMITER);
    csvRows.push(values);
  });
  return csvRows.join('\n');
};

export const showBlobInBrowser = (blob: any) => {
  var url = URL.createObjectURL(blob);
  window.open(url, '_blank');
};

export const useScrollbarWidth = () => {
  const didCompute = useRef(false);
  const widthRef = useRef(0);

  if (didCompute.current) return widthRef.current;

  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode?.removeChild(outer);

  didCompute.current = true;
  widthRef.current = scrollbarWidth;

  return scrollbarWidth;
};

export function replaceEscapedNewlines(
  input: string | undefined
): string | undefined {
  if (!input) return undefined;
  return input.replace(/\\n/g, '\n');
}

export function formatSizeInMB(sizeInBytes: number) {
  const sizeInMB = sizeInBytes / (1024 * 1024);
  return sizeInMB.toFixed(1) + ' MB';
}

export function formatDateAndTime(date: string) {
  const dateObj = typeof date === 'string' ? new Date(date) : date;

  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed in JavaScript
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}
