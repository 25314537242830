import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface HeaderState {
  burgerOpened: boolean;
}

const initialState: HeaderState = {
  burgerOpened: false,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setBurgerOpened: (state, action: PayloadAction<boolean>) => {
      state.burgerOpened = action.payload;
    },
  },
});

export const { setBurgerOpened } = headerSlice.actions;

export default headerSlice.reducer;
