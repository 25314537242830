import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum Notification {
  None,
  ChangeLanguage,
  InvalidLogin,
  Registration,
  RegistrationFailed,
  LogOut,
  Available,
  SoonAvailable,
  EndOfLife,
  Discontinued,
  AddDraft,
  AddWishlist,
  FullAccess,
  RemoveConfiguration,
  SaveToLater,
  OrderSent,
  RemoveDraft,
  ChangePassword,
  ForgotPassword,
  ContactSent,
  ContactFailed,
  ChangePasswordSuccess,
  ChangePasswordFailed,
  ForgotPasswordSuccess,
  ForgotPasswordFailed,
  ResetPasswordSuccess,
  ResetPasswordFailed,
  TooMuchItemsSelected,
  DiscardProduct,
  InvalidUserUpdate,
  UserUpdateSuccess,
  SaveDraft,
  SaveWishlist,
  TechArea,
}

interface NotificationState {
  notification: Notification;
  url?: string;
}

const initialState: NotificationState = {
  notification: Notification.None,
  url: '',
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<any>) => {
      if (action?.payload?.url) {
        state.notification = action.payload.notification;
        state.url = action.payload.url;
      } else {
        state.notification = action.payload;
      }
    },
  },
});

export const { setNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
