import axios from 'axios';
import qs from 'qs';

export const fetchProductsWithFields = async (
  fields: any = [],
  filters: any = {},
  sort: string = 'createdAt',
  page: number = 1,
  pageSize: number = 6,
  populate: any = {},
  config: any = {}
) => {
  let newFilter: any = {
    fields,
    filters,
    sort,
    pagination: {
      pageSize: pageSize,
      page,
    },
    populate,
  };

  const query = qs.stringify(newFilter, {
    encodeValuesOnly: true,
  });
  const response = await axios.get(`/base-products/find-with-fields?${query}`, {
    ...config,
  });
  return response.data;
};

export const fetchProduct = async (productId: string, config: any = {}) => {
  const newFilter: any = {
    filters: {
      mpn: {
        $eq: productId,
      },
    },
    populate: 'deep,3',
  };
  const query = qs.stringify(newFilter, {
    encodeValuesOnly: true,
  });
  const response = await axios.get(`/base-products?${query}`, {
    ...config,
  });
  return response.data;
};
