import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import modal from './slices/modalSlice';
import changePasswordModal from './slices/modals/changePasswordModalSlice';
import consultingModal from './slices/modals/consultingModalSlice';
import loginModal from './slices/modals/loginModalSlice';
import registerModal from './slices/modals/registerModalSlice';
import forgotPasswordModal from './slices/modals/forgotPasswordModalSlice';
import comparatorModal from './slices/modals/comparatorModal';
import user, { userSlice } from './slices/userSlice';
import productPage from './slices/productPageSlice';
import shopPage from './slices/shopPageSlice';
import notification from './slices/notificationSlice';
import changeLanguageNotification from './slices/notifications/changeLanguageNotificationSlice';
import wishlistDraft from './slices/wishlistDraftSlice';
import dashboard from './slices/dashboardSlice';
import header from './slices/headerSlice';
import storage from 'redux-persist/lib/storage';
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  PersistedState,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';

const reducers = combineReducers({
  modal,
  modals: combineReducers({
    loginModal,
    registerModal,
    changePasswordModal,
    consultingModal,
    forgotPasswordModal,
    comparatorModal,
  }),
  user,
  productPage,
  shopPage,
  notification,
  notifications: combineReducers({
    changeLanguageNotification,
  }),
  wishlistDraft,
  dashboard,
  header,
});

const modalsWhitelist = createWhitelistFilter('modals', ['comparatorModal']);

const migrations: {
  [key: number]: (
    state: PersistedState | undefined
  ) => PersistedState | undefined;
} = {
  1: (state) => {
    const currentState = state as any;
    currentState.user = userSlice.caseReducers.logOut(currentState.user);
    return currentState;
  },
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['notifications', 'wishlistDraft', 'productPage', 'header'],
  transforms: [modalsWhitelist],
  migrate: createMigrate(migrations, {
    debug: process.env.NODE_ENV !== 'production',
  }),
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
