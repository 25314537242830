import { ReactComponent as Collapse } from './collapse.svg';
import { ReactComponent as Down2 } from './down-2.svg';
import { ReactComponent as DownCircle1 } from './down-circle-1.svg';
import { ReactComponent as DownCircle } from './down-circle.svg';
import { ReactComponent as DownLeftCircle } from './down-left-circle.svg';
import { ReactComponent as DownLeft } from './down-left.svg';
import { ReactComponent as DownRightCircle } from './down-right-circle.svg';
import { ReactComponent as DownRight } from './down-right.svg';
import { ReactComponent as DownSquare2 } from './down-square-2.svg';
import { ReactComponent as DownSquare } from './down-square.svg';
import { ReactComponent as Down } from './down.svg';
import { ReactComponent as Expand } from './expand.svg';
import { ReactComponent as Left2 } from './left-2.svg';
import { ReactComponent as LeftCircle1 } from './left-circle-1.svg';
import { ReactComponent as LeftCircle } from './left-circle.svg';
import { ReactComponent as LeftSquare2 } from './left-square-2.svg';
import { ReactComponent as LeftSquare } from './left-square.svg';
import { ReactComponent as Left } from './left.svg';
import { ReactComponent as Right2 } from './right-2.svg';
import { ReactComponent as RightCircle1 } from './right-circle-1.svg';
import { ReactComponent as RightCircle } from './right-circle.svg';
import { ReactComponent as RightSquare2 } from './right-square-2.svg';
import { ReactComponent as RightSquare4 } from './right-square-4.svg';
import { ReactComponent as RightSquare } from './right-square.svg';
import { ReactComponent as Right } from './right.svg';
import { ReactComponent as Stretch } from './stretch.svg';
import { ReactComponent as Swap2 } from './swap-2.svg';
import { ReactComponent as Swap3 } from './swap-3.svg';
import { ReactComponent as Swap } from './swap.svg';
import { ReactComponent as Up2 } from './up-2.svg';
import { ReactComponent as UpCircle1 } from './up-circle-1.svg';
import { ReactComponent as UpCircle } from './up-circle.svg';
import { ReactComponent as UpLeftCircle } from './up-left-circle.svg';
import { ReactComponent as UpLeft } from './up-left.svg';
import { ReactComponent as UpRightCircle } from './up-right-circle.svg';
import { ReactComponent as UpRight } from './up-right.svg';
import { ReactComponent as UpSquare2 } from './up-square-2.svg';
import { ReactComponent as UpSquare } from './up-square.svg';
import { ReactComponent as Up } from './up.svg';

interface IProps {
  width?: string;
  height?: string;
  fill?: string;
  stroke?: string;
  className?: string;
  transform?: string;
  onClick?: () => void;
}

export const CollapseIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Collapse
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Down2Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
  transform,
  onClick,
}: IProps) => (
  <Down2
    onClick={() => onClick && onClick()}
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
      transform: transform,
    }}
  />
);

export const DownCircle1Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <DownCircle1
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const DownCircleIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <DownCircle
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const DownLeftCircleIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <DownLeftCircle
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const DownLeftIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <DownLeft
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const DownRightCircleIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <DownRightCircle
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const DownRightIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <DownRight
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const DownSquare2Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <DownSquare2
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const DownSquareIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <DownSquare
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const DownIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Down
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const ExpandIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Expand
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Left2Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Left2
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LeftCircle1Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <LeftCircle1
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LeftCircleIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <LeftCircle
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LeftSquare2Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <LeftSquare2
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LeftSquareIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <LeftSquare
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LeftIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Left
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Right2Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Right2
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const RightCircle1Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <RightCircle1
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const RightCircleIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <RightCircle
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const RightSquare2Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <RightSquare2
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const RightSquare4Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <RightSquare4
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const RightSquareIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <RightSquare
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const RightIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Right
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const StretchIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Stretch
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Swap2Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Swap2
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Swap3Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Swap3
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const SwapIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Swap
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Up2Icon = ({ width, height, fill, stroke, className }: IProps) => (
  <Up2
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const UpCircle1Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <UpCircle1
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const UpCircleIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <UpCircle
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const UpLeftCircleIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <UpLeftCircle
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const UpLeftIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <UpLeft
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const UpRightCircleIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <UpRightCircle
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const UpRightIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <UpRight
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const UpSquare2Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <UpSquare2
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const UpSquareIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <UpSquare
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const UpIcon = ({ width, height, fill, stroke, className }: IProps) => (
  <Up
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);
