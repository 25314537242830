import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface ProductEntry {
  id: string;
  summary?: object;
  addonsPrice?: number;
}

interface Products {
  [key: string]: ProductEntry;
}

interface ComparatorModalState {
  products: Products;
}

const initialState: ComparatorModalState = {
  products: {},
};

export const comparatorModalSlice = createSlice({
  name: 'comparatorModal',
  initialState,
  reducers: {
    addProduct: (state, action: PayloadAction<ProductEntry>) => {
      if (Object.keys(state.products).length < 4) {
        state.products[action.payload.id] = action.payload;
      }
    },
    removeProduct: (state, action: PayloadAction<string>) => {
      delete state.products[action.payload];
    },
    removeAllProducts: (state) => {
      Object.keys(state.products).forEach((key) => {
        delete state.products[key];
      });
    },
  },
});

export const { addProduct, removeProduct, removeAllProducts } =
  comparatorModalSlice.actions;

export default comparatorModalSlice.reducer;
