import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface WishlistDraftState {
  selectedIndex: number;
  wishlistProducts: Array<any>;
  draftProducts: Array<any>;
}

const initialState: WishlistDraftState = {
  selectedIndex: -1,
  wishlistProducts: [],
  draftProducts: [],
};

export const wishlistDraftSlice = createSlice({
  name: 'wishlistDraft',
  initialState,
  reducers: {
    setSelectedIndex: (state, action: PayloadAction<number>) => {
      state.selectedIndex = action.payload;
    },
    setWishlistProducts: (state, action: PayloadAction<Array<any>>) => {
      state.wishlistProducts = action.payload;
    },
    setDraftProducts: (state, action: PayloadAction<Array<any>>) => {
      state.draftProducts = action.payload;
    },
    deleteWishlistProduct: (state, action: PayloadAction<number>) => {
      if (
        action.payload >= 0 &&
        action.payload < state.wishlistProducts.length
      ) {
        state.wishlistProducts.splice(action.payload, 1);
      }
    },
    deleteDraftProduct: (state, action: PayloadAction<number>) => {
      if (action.payload >= 0 && action.payload < state.draftProducts.length) {
        state.draftProducts.splice(action.payload, 1);
      }
    },
  },
});

export const {
  setSelectedIndex,
  setWishlistProducts,
  setDraftProducts,
  deleteWishlistProduct,
  deleteDraftProduct,
} = wishlistDraftSlice.actions;

export default wishlistDraftSlice.reducer;
