import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routeTranslations } from '../App'; // Import translations
import { Language } from '../utils';

export function RedirectToSecurity() {
  const navigate = useNavigate();
  const { lang } = useParams(); // Extract the language from the URL
  const currentLang = (lang as Language) || 'en'; // Default to 'en' if no language is provided
  const routes = routeTranslations[currentLang]; // Get translated paths for the current language

  useEffect(() => {
    // Redirect to the correct sector based on the language
    navigate(`/${currentLang}/${routes.sectors}/${routes.security}`);
  }, [navigate, currentLang, routes]);

  return null;
}
