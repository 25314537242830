import React from 'react';
import { NavLink } from 'react-router-dom';

interface IProps {
  to: any;
  children: React.ReactNode;
  onClick?: any;
  className?: string;
  activeClassName?: string;
  end?: boolean;
  style?: any;
}

const SelectableNavLink = ({
  to,
  children,
  onClick,
  className,
  activeClassName,
  end = true,
  style,
}: IProps) => {
  return (
    <NavLink
      style={style}
      end={end}
      className={({ isActive }) =>
        `${className} ${isActive ? activeClassName : ''}`
      }
      onClick={onClick}
      to={to}
    >
      {children}
    </NavLink>
  );
};
export default SelectableNavLink;
