import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { Modal, setModal } from '../../redux/slices/modalSlice';
import Button, { IconPlacement, Size, Style } from '../Button/Button';
import {
  DownIcon,
  RightIcon,
  RightIcon as RightIcon24,
} from '../Icons/Arrows/24/icons';
import { SearchBarProducts } from '../SearchBar/SearchBar';
import UserPanel from '../UserPanel/UserPanel';
import { ButtonType } from './Header';
import s from './Header.module.scss';
import { routeTranslations } from '../../App'; // Import routeTranslations
import SelectableNavLink from '../SelectableNavLink/SelectableNavLink';
import { scroller } from 'react-scroll';
import { Language } from '../../utils';

export const HeaderDesktopUpper = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentLang =
    (window.location.pathname.split('/')[1] as Language) || 'en';
  const routes = routeTranslations[currentLang]; // Get correct translations based on language

  const handleNavigation = (linkPath: string, isInternalScroll: boolean) => {
    if (isInternalScroll) {
      // Scroll to section on the homepage
      navigate(`/${currentLang}/#${linkPath}`);
      setTimeout(() => {
        scroller.scrollTo(linkPath, {
          smooth: true,
          duration: 800,
          delay: 0,
        });
      }, 300);
    } else {
      // Navigate to page
      navigate(linkPath);
    }
  };

  return (
    <div className={s.upperWrapper}>
      <div className={s.left}>
        {props?.headerTranslation?.pages?.map((elem: any, index: number) => {
          let linkPath = elem.link;

          // Check if it's a relative or absolute path
          if (elem.link.startsWith('/')) {
            // Absolute path: add the language prefix
            linkPath = `/${currentLang}${elem.link}`;
          } else if (routes[elem.link]) {
            // Handle internal scrolling by language
            linkPath = routes[elem.link];
          }

          const isInternalScroll = !elem.link.startsWith('/');

          return (
            <span
              key={index} // Ensure unique key for React's rendering
              className='medium-bold add-pointer'
              onClick={() => handleNavigation(linkPath, isInternalScroll)} // Navigate using the translated path
            >
              {elem.title}
            </span>
          );
        })}
      </div>
      <div className={s.right}>
        <div className={s.rightButtons}>
          <Button
            className={s.consultingButton}
            style={Style.Orange}
            minimalContentHeight='24px'
            iconHover={<RightIcon24 stroke='#FEFEFE' />}
            iconPlacement={IconPlacement.Right}
            size={Size.Normal}
            onClick={() => dispatch(setModal(Modal.Consulting))}
          >
            <b>
              {
                props?.headerTranslation?.buttons?.[
                  ButtonType.ProductConsulting
                ]
              }
            </b>
          </Button>
          {props.loggedIn ? (
            <UserPanel />
          ) : (
            <Button
              style={Style.BlackOutline}
              size={Size.Normal}
              icon={<RightIcon stroke='#212121' />}
              iconHover={<DownIcon stroke='#FEFEFE' />}
              iconPlacement={IconPlacement.Right}
              onClick={() => dispatch(setModal(Modal.Login))}
            >
              {props?.headerTranslation?.buttons?.[ButtonType.SignIn]}
            </Button>
          )}
        </div>
        <p
          onClick={() => dispatch(setModal(Modal.Language))}
          className='medium-bold'
        >
          {props?.headerTranslation?.buttons?.[ButtonType.Language]}
        </p>
      </div>
    </div>
  );
};
export const HeaderDesktopLower = (props: any) => {
  const currentLang =
    (window.location.pathname.split('/')[1] as Language) || 'en';
  const routes = routeTranslations[currentLang]; // Get the correct routes for the current language

  return (
    <div className={s.lower}>
      <div className={s.lowerWrapper}>
        <div className={s.left}>
          <p className={`medium ${s.noLink}`}>
            {props?.headerTranslation?.categories?.title}:
          </p>

          {[...props?.resource]
            ?.sort(
              (a: any, b: any) => a.orderOfAppearance - b.orderOfAppearance
            )
            ?.map((cat: any) => {
              const linkStyle =
                cat.link === '/product-archives' ? { color: 'orange' } : {};

              const route = routes.categories;

              if (route) {
                // Ensure the correct combination of slashes between the language, route, and category link
                const formattedLink = `/${currentLang}/${route.replace(
                  /^\/|\/$/g,
                  ''
                )}/${cat.link.replace(/^\/|\/$/g, '')}`;

                return (
                  <SelectableNavLink
                    key={cat.name}
                    activeClassName={s.selected}
                    className='medium-bold'
                    to={formattedLink} // Use the properly formatted language-based path
                    style={linkStyle}
                  >
                    {cat.name.toUpperCase()}
                  </SelectableNavLink>
                );
              }

              // Always return something, even if it's null
              return null;
            })}
        </div>
        <div className={s.right}>
          <SearchBarProducts
            searchProducts={true}
            className={s.searchBar}
            placeholder={props?.headerTranslation?.searchbar?.text}
          />
        </div>
      </div>
    </div>
  );
};

