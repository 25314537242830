import { animateScroll, scroller } from 'react-scroll';
import { RightSquare4Icon } from '../Icons/Arrows/24/icons';
import { SearchBarProducts } from '../SearchBar/SearchBar';
import s from './Footer.module.scss';
import withTranslation from '../../hoc/withTranslation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TemplateAnimation, {
  AnimationTemplates,
} from '../Animations/TemplateAnimation';
import { useResource } from '../../hoc/withResource';

function getStringType(str: string): 'email' | 'http' | null {
  const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
  const httpPattern =
    /https?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+|(www\.[a-zA-Z0-9.-]+)/;

  if (emailPattern.test(str)) return 'email';
  if (httpPattern.test(str)) return 'http';

  return null;
}

function getAbsoluteURL(url: string): string {
  if (url.startsWith('www.')) {
    return 'http://' + url;
  }
  return url;
}

interface HyperlinkItemProps {
  item: string;
}

const HyperlinkItem: React.FC<HyperlinkItemProps> = ({ item }) => {
  const type = getStringType(item);

  if (type === 'email') {
    return <a href={`mailto:${item}`}>{item}</a>;
  } else if (type === 'http') {
    return (
      <a href={getAbsoluteURL(item)} target='_blank' rel='noopener noreferrer'>
        {item}
      </a>
    );
  } else {
    return <span>{item}</span>;
  }
};

const GridItem = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentLang = location.pathname.split('/')[1] || 'en'; // Get current language from URL

  const scrollToSection = (sectionId: string) => {
    const newUrl = `/${currentLang}/#${sectionId}`;

    if (location.pathname !== `/${currentLang}`) {
      navigate(`/${currentLang}/`); // Navigate to homepage
      setTimeout(() => {
        scroller.scrollTo(sectionId, {
          ignoreCancelEvents: true,
          duration: 700,
          delay: 200,
          smooth: 'easeInOutQuart',
        });
        // Update the URL
        window.history.replaceState(null, '', newUrl);
      }, 1200);
    } else {
      scroller.scrollTo(sectionId, {
        ignoreCancelEvents: true,
        duration: 700,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
      // Update the URL
      window.history.replaceState(null, '', newUrl);
    }
  };

  return (
    <div className={s.gridItem}>
      <p className='body-1'>
        <b>{props?.data?.title}</b>
      </p>
      <ul>
        {props?.data?.items?.map((item: any, i: number) => {
          if (item.link) {
            // If the link is missing a leading slash, treat it as a section to scroll to
            if (!item.link.startsWith('/')) {
              return (
                <li
                  onClick={() => {
                    scrollToSection(item.link); // Scroll to section on homepage
                  }}
                  key={i}
                >
                  <p className='medium'>{item.title}</p>
                </li>
              );
            } else {
              // For other links, ensure the correct navigation with language prefix
              return (
                <li
                  onClick={() => {
                    navigate(`/${currentLang}${item.link}`); // Navigate to full page path
                  }}
                  key={i}
                >
                  <p className='medium'>{item.title}</p>
                </li>
              );
            }
          } else if (item.fontAwesome) {
            return (
              <li
                key={i}
                onClick={() => {
                  window.open(item.url, '_blank');
                }}
              >
                <div className={s.socialMedia}>
                  <div>
                    <i className={item.fontAwesome}></i>
                  </div>
                  <p className='medium'>{item.title}</p>
                </div>
              </li>
            );
          } else {
            return (
              <li key={i}>
                <p style={{ cursor: 'default' }} className='medium'>
                  <HyperlinkItem item={item.title} />
                </p>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

const Footer = (props: any) => {
  const translation = props?.translation?.items?.[2]?.items;
  const { data: categories, status: categoriesStatus } =
    useResource('categories');
  const [updatedArr, setUpdatedArray] = useState({});

  useEffect(() => {
    if (categoriesStatus === 'success') {
      const newArr = translation?.map((translation: any) => {
        const match = categories?.find(
          (category: any) => category.key === translation.key
        )?.orderOfAppearance;
        translation.orderOfAppearance = match;
        return translation;
      });
      const sortedArr = newArr?.sort(
        (a: any, b: any) => a.orderOfAppearance - b.orderOfAppearance
      );
      setUpdatedArray({
        items: sortedArr,
        title: props?.translation?.items?.[2]?.title,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesStatus, translation]);

  return (
    categoriesStatus === 'success' && (
      <footer className={s.footer}>
        <TemplateAnimation
          templates={[AnimationTemplates.Opacity]}
          exit={false}
          once={false}
          duration={0.5}
          className={s.content}
        >
          <div className={s.grid}>
            <div className={`${s.gridItem} ${s.spanFull}`}>
              <p className='body-1'>
                <b>{props?.translation?.aboutUs?.title}</b>
              </p>
              <p className={`body-2 ${s.textBlock}`}>
                {props?.translation?.aboutUs?.body}
              </p>
            </div>
            {props?.translation?.items?.map((item: any, i: number) => {
              if (i === 2) {
                return <GridItem data={updatedArr} key={i} />;
              } else {
                return <GridItem data={item} key={i} />;
              }
            })}
          </div>
          <div className={s.searchBarWrapper}>
            <SearchBarProducts
              searchProducts={true}
              className={s.searchBar}
              placeholder={props?.translation?.searchbar?.text}
            />
          </div>
          <div className={s.bottom}>
            <img src='/img/logo/logo-footer.png' alt='logo-footer' />
            <div
              className={s.back}
              onClick={() =>
                animateScroll.scrollTo(0, {
                  ignoreCancelEvents: true,
                  duration: 2000,
                  smooth: 'easeOutQuint',
                })
              }
            >
              <RightSquare4Icon className={s.backIcon} />
              <p className='body-2'>{props?.translation?.others?.[0]}</p>
            </div>
          </div>
          <p className={`body-2 ${s.copyright}`}>
            {new Date().getFullYear()} - Hawc-Servers.{' '}
            {props?.translation?.others?.[1]}.
          </p>
        </TemplateAnimation>
      </footer>
    )
  );
};

export default withTranslation('footer')(Footer);
