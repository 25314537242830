import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../redux/hooks';
import {
  addPriceToProduct,
  fetchProductData,
  getProductData,
  useAxiosAuthConfig,
} from '../utils';

enum Status {
  InProgress = 1,
  Completed,
}

export enum Type {
  Cart,
  Draft,
  Quotation,
  InProgress,
}

const getTypeRoute = (type: Type) => {
  switch (type) {
    case Type.Cart:
      return '/wishlists';
    case Type.Draft:
      return '/drafts';
  }
};

const preparePciExpress = (type: string, obj: any) => {
  const results: any = [];
  obj.pciExpress.forEach((elem: any) => {
    if (elem.pciExpressVersion.find((version: any) => version.name === type)) {
      results.push(JSON.parse(JSON.stringify(elem)));
    }
  });
  return results;
};

export const withWishlistDraftGet = (type: Type) => (WrappedComponent: any) => {
  return (props: any) => {
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const userId = useAppSelector((state) => state?.user?.userData?.user?.id);
    const config = useAxiosAuthConfig();
    const route = getTypeRoute(type);
    const qs = require('qs');
    const query = qs.stringify(
      {
        filters: {
          user: {
            id: {
              $eq: userId,
            },
          },
        },
        populate: 'deep,3',
      },
      {
        encodeValuesOnly: true,
      }
    );

    useEffect(() => {
      axios
        .get(`${route}?${query}`, config)
        .then(async (response) => {
          const data = response.data.data;
          for (let index in data) {
            let productId = data[index].product.id;
            data[index].product = await fetchProductData(
              productId,
              userId,
              config
            );
            data[index].pciExpress1x =
              data[index].settings.pciExpress1x.length > 0
                ? preparePciExpress('x1', data[index])
                : [];
            data[index].pciExpress2x =
              data[index].settings.pciExpress2x.length > 0
                ? preparePciExpress('x2', data[index])
                : [];
            data[index].pciExpress4x =
              data[index].settings.pciExpress4x.length > 0
                ? preparePciExpress('x4', data[index])
                : [];
            data[index].pciExpress8x =
              data[index].settings.pciExpress8x.length > 0
                ? preparePciExpress('x8', data[index])
                : [];
            data[index].pciExpress16x =
              data[index].settings.pciExpress16x.length > 0
                ? preparePciExpress('x16', data[index])
                : [];
            addPriceToProduct(data[index]);
          }
          setData(data);
          setLoaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }, [query, config, route, userId]);
    switch (type) {
      case Type.Cart:
        return (
          <WrappedComponent
            loadedWishlistData={loaded}
            wishlistData={data}
            {...props}
          />
        );
      case Type.Draft:
        return (
          <WrappedComponent
            loadedDraftData={loaded}
            draftData={data}
            {...props}
          />
        );
    }
  };
};

export const withWishlistDraftUpdate = (WrappedComponent: any) => {
  return (props: any) => {
    const userId = useAppSelector((state) => state?.user?.userData?.user?.id);
    const config = useAxiosAuthConfig();
    const addProduct = async (
      where: Type,
      data: any,
      pdfId: null | number = null,
      uuid: null | string = null
    ) => {
      switch (where) {
        case Type.Cart:
          return await axios.post(
            '/wishlists',
            { data: { user: userId, ...data } },
            config
          );
        case Type.Draft:
          return await axios.post(
            '/drafts',
            { data: { user: userId, ...data } },
            config
          );
        case Type.Quotation:
          return await axios.post(
            '/orders',
            {
              data: {
                user: userId,
                configurationPDF: pdfId,
                referenceNumber: uuid,
                status: Status.InProgress,
                ...data,
              },
            },
            config
          );
      }
    };
    const productWithPdf = async (
      file: any,
      product: any,
      quantity: any,
      refNumber: string
    ) => {
      const formData = new FormData();
      formData.append('files', file, refNumber + '.pdf');
      axios
        .post('/upload', formData, config)
        .then((response) => {
          addProduct(
            Type.Quotation,
            {
              ...getProductData(product),
              quantity: quantity,
            },
            response.data[0].id,
            refNumber
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const updateProduct = async (where: Type, id: number, data: any) => {
      switch (where) {
        case Type.Cart:
          return await axios.put(
            `/wishlists/${id}`,
            { data: { user: userId, ...data } },
            config
          );
        case Type.Draft:
          return await axios.put(
            `/drafts/${id}`,
            { data: { user: userId, ...data } },
            config
          );
      }
    };
    const deleteProduct = async (where: Type, id: number) => {
      switch (where) {
        case Type.Cart:
          return await axios.delete(`/wishlists/${id}`, config);
        case Type.Draft:
          return await axios.delete(`/drafts/${id}`, config);
      }
    };
    return (
      <WrappedComponent
        addProduct={addProduct}
        updateProduct={updateProduct}
        deleteProduct={deleteProduct}
        productWithPdf={productWithPdf}
        {...props}
      />
    );
  };
};
