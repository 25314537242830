import React from 'react';

interface LoadingIndicatorProps {
  message?: string;
}

const Loader: React.FC<LoadingIndicatorProps> = () => {
  return (
    <div style={styles.container}>
      <div style={styles.spinner}></div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  spinner: {
    width: '50px',
    height: '50px',
    border: '5px solid rgba(6, 113, 224,0.3)',
    borderTop: '5px solid rgb(6, 113, 224)',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
};

export default Loader;
