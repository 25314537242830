import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Type } from '../../hoc/withWishlistDraft';

export interface ProductEditState {
  index: number;
  where: Type;
  additionalHdd: any;
  service: any;
  accessory: any;
  pciExpress1x: any;
  pciExpress2x: any;
  pciExpress4x: any;
  pciExpress8x: any;
  pciExpress16x: any;
  quantity: any;
  product: any;
  cpu: any;
}

interface ProductPageState {
  configure: boolean;
  productEdit: ProductEditState | null;
  summary?: object;
  addonsPrice?: number;
}

const initialState: ProductPageState = {
  configure: false,
  productEdit: null,
};

export const productPageSlice = createSlice({
  name: 'productPage',
  initialState,
  reducers: {
    setConfigure: (state, action: PayloadAction<boolean>) => {
      state.configure = action.payload;
    },
    setProductEdit: (state, action: PayloadAction<any>) => {
      if (action.payload == null) {
        state.productEdit = null;
      } else {
        const product = action.payload.product;
        const index = action.payload.index;
        const where = action.payload.where;
        state.productEdit = {
          index,
          where,
          quantity: product.quantity,
          ...product.settings,
          ...action.payload,
        };
      }
    },
    setSummary: (state, action: PayloadAction<object>) => {
      state.summary = action.payload;
    },
    setAddonsPrice: (state, action: PayloadAction<number>) => {
      state.addonsPrice = action.payload;
    },
  },
});

export const { setConfigure, setProductEdit, setSummary, setAddonsPrice } =
  productPageSlice.actions;

export default productPageSlice.reducer;
