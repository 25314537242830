import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import qs from 'qs';
import { useTranslation } from 'react-i18next';

export const useResource = (
  url: string,
  populate: string | object = '*',
  locale?: string
) => {
  const { i18n } = useTranslation();
  const localeShortcut = locale ?? i18n?.language?.slice(0, 2);
  const query = qs.stringify(
    {
      locale: localeShortcut,
      populate: populate,
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: [url, query],
    queryFn: () =>
      axios.get(`/${url}?${query}`).then((response) => response.data.data),
  });
};
