import Slanted from './slanted.svg';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { useMediaQuery } from 'usehooks-ts';
import SloganBar from '../SloganBar/SloganBar';
import s from './Header.module.scss';
import withTranslation from '../../hoc/withTranslation';
import { Events } from 'react-scroll';
import { useCallback, useEffect, useRef, useState } from 'react';
import HeaderMobile from './HeaderMobile';
import { HeaderDesktopUpper, HeaderDesktopLower } from './HeaderDesktop';
import { useResource } from '../../hoc/withResource';
import { routeTranslations } from '../../App'; // Import routeTranslations
import { Language } from '../../utils';

export enum ButtonType {
  ProductConsulting,
  SignIn,
  Language,
  GetStarted,
}

const Header = (props: any) => {
  const headerTranslation = props?.translation?.[0]?.data;
  const languageTranslation = props?.translation?.[1]?.data;
  const burgerOpened = useAppSelector((state) => state.header.burgerOpened);
  const max1600 = useMediaQuery('(max-width: 1600px)');
  const isMobile = useMediaQuery('(max-width: 768px)');
  const loggedIn = useAppSelector((state) => state?.user?.loggedIn);
  const location = useLocation();
  const headerRef = useRef<HTMLDivElement>(null);
  const [scrollPosY, setScrollPosY] = useState(0);
  const { data: categories, status: categoriesStatus } =
    useResource('categories');
  const { data: sectors, status: sectorsStatus } = useResource('sectors');

  const currentLang =
    (window.location.pathname.split('/')[1] as Language) || 'en';
  const routes = routeTranslations[currentLang]; // Get the translated routes for the current language

  useEffect(() => {
    Events.scrollEvent.register('begin', function (to, element) {});
    Events.scrollEvent.register('end', function (to, element) {});
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);
  const onScroll = useCallback(
    (e: any) => {
      let header = headerRef?.current;
      if (header) {
        if (scrollPosY > window.scrollY) {
          header.style.top = '0';
        } else {
          header.style.top = '-' + header.clientHeight + 'px';
        }
      }
      setScrollPosY(window.scrollY);
    },
    [scrollPosY]
  );
  useEffect(() => {
    window.addEventListener('scroll', onScroll, {
      passive: false,
    });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  // Adjust the paths based on the current language
  const excludePaths = [
    `/${currentLang}/${routes.dashboard}`,
    `/${currentLang}/${routes.distributorArea}`,
    `/${currentLang}/${routes.salesConditions}`,
    `/${currentLang}/${routes.termsAndConditions}`,
    `/${currentLang}/${routes.privacyPolicy}`,
    `/${currentLang}/${routes.techArea}`,
  ];

  return (
    categoriesStatus === 'success' &&
    sectorsStatus === 'success' && (
      <>
        <header
          ref={headerRef}
          style={{
            position: burgerOpened ? 'fixed' : 'sticky',
            top: burgerOpened ? '0' : headerRef?.current?.style?.top,
            height: burgerOpened ? '100%' : 'auto',
            overflow: 'visible',
          }}
          className={s.header}
        >
          <div className={s.upper}>
            {isMobile ? (
              <HeaderMobile
                burgerOpened={burgerOpened}
                loggedIn={loggedIn}
                headerTranslation={headerTranslation}
                languageTranslation={languageTranslation}
                sectors={sectors}
                resource={categories}
              />
            ) : (
              <>
                <RouterLink to={`/${currentLang}`} className={s.logo}>
                  {!max1600 && <img src={Slanted} alt='logo-background' />}
                  <div className={s.logoImage}>
                    <img src='/img/logo/logo-header.png' alt='logo-header' />
                  </div>
                </RouterLink>
                <HeaderDesktopUpper
                  headerTranslation={headerTranslation}
                  loggedIn={loggedIn}
                  resource={categories}
                />
              </>
            )}
          </div>
          {!isMobile && (
            <HeaderDesktopLower
              headerTranslation={headerTranslation}
              resource={categories}
            />
          )}
        </header>
        {/* Check if the current path is one of the excluded paths */}
        {excludePaths.includes(location.pathname) ? (
          <></>
        ) : (
          <SloganBar slogan={headerTranslation?.slogan?.text} />
        )}
      </>
    )
  );
};

export default withTranslation(['header', 'modal-language'])(Header);
