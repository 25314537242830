import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface ForgotPasswordModalState {
  email: string;
}

const initialState: ForgotPasswordModalState = {
  email: '',
};

export const forgotPasswordModalSlice = createSlice({
  name: 'forgotPasswordModal',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
});

export const { setEmail } = forgotPasswordModalSlice.actions;

export default forgotPasswordModalSlice.reducer;
