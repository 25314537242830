import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum ChangePasswordError {
  None,
  InvalidPassword,
  SamePassword,
  Other,
}

interface ChangePasswordModalState {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
  error: ChangePasswordError;
}

const initialState: ChangePasswordModalState = {
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
  error: ChangePasswordError.None,
};

export const changePasswordModalSlice = createSlice({
  name: 'changePasswordModal',
  initialState,
  reducers: {
    setCurrentPassword: (state, action: PayloadAction<string>) => {
      state.currentPassword = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setPasswordConfirmation: (state, action: PayloadAction<string>) => {
      state.passwordConfirmation = action.payload;
    },
    setError: (state, action: PayloadAction<ChangePasswordError>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setCurrentPassword,
  setPassword,
  setPasswordConfirmation,
  setError,
} = changePasswordModalSlice.actions;

export default changePasswordModalSlice.reducer;
