import React, { useEffect, lazy, Suspense } from 'react';
import s from './ComparatorBar.module.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Modal, setModal } from '../../redux/slices/modalSlice';
import withProductsComparator from '../../hoc/withProductsComparator';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  removeProduct,
  removeAllProducts,
} from '../../redux/slices/modals/comparatorModal';
import withTranslation from '../../hoc/withTranslation';
import { routeTranslations } from '../../App';
import { Language } from '../../utils';

// Lazy load non-critical components
const Button = lazy(() => import('../Button/Button'));
const { Size, Style } = await import('../Button/Button'); // Import the enums directly
const CloseIcon = lazy(() =>
  import('../Icons/UI/24/icons').then((module) => ({
    default: module.CloseIcon,
  }))
);

const ComparatorBar = (props: any) => {
  const { setProducts, comparatorProducts } = props;
  const dispatch = useAppDispatch();
  const products = useAppSelector(
    (state) => state.modals.comparatorModal.products
  );

  useEffect(() => {
    setProducts(Object.keys(products));
  }, [products, setProducts, dispatch]);

  const navigate = useNavigate();

  const location = useLocation();

   const currentLang =
     (window.location.pathname.split('/')[1] as Language) || 'en';
   // Get the translated routes for the current language
   const routes = routeTranslations[currentLang];

   // Dynamically check if the current path includes the translated "categories" path
   const isInCategories = location.pathname.includes(
     `/${currentLang}/${routes.categories}`
   );

  return (
    isInCategories &&
    comparatorProducts.length > 0 && (
      <div className={s.container}>
        <div className={s.products}>
          {comparatorProducts.map((product: any) => (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(
                  `/${currentLang}/${
                    routes.categories
                  }/${product.category.name.toLowerCase()}/${product.mpn}`
                )
              }
              key={product.id}
              className={s.product}
            >
              <Suspense fallback={<div></div>}>
                <div
                  className={s.close}
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(removeProduct(product.id));
                  }}
                >
                  <CloseIcon stroke='#FEFEFE' />
                </div>
                <img
                  src={
                    process.env.REACT_APP_HOST_URL +
                    product.images[0].url +
                    '?width=100'
                  }
                  alt={product.mpn}
                />
              </Suspense>
            </div>
          ))}
        </div>
        <div className={s.buttons}>
          <Suspense fallback={<div></div>}>
            <Button
              className={s.configureButton}
              height='32px'
              size={Size.Small}
              style={Style.Primary}
              onClick={() => dispatch(setModal(Modal.Comparator))}
            >
              {props.translation?.buttons?.compare}
            </Button>
            <Button
              className={s.configureButton}
              height='32px'
              size={Size.Small}
              style={Style.Primary}
              onClick={() => dispatch(removeAllProducts())}
            >
              {props.translation?.buttons?.compareClear}
            </Button>
          </Suspense>
        </div>
      </div>
    )
  );
};

export default withTranslation('comparator')(
  withProductsComparator(ComparatorBar)
);
