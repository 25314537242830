import React from 'react';
import { motion, Variants } from 'framer-motion';

const defaultDuration = 0.2;

export enum TransitionType {
  Linear,
  Spring,
  EaseIn,
  EaseOut,
}

interface IProps {
  variants: Variants;
  delay?: number;
  exit: boolean;
  duration?: number;
  once: boolean;
  className?: string;
  children: React.ReactNode;
  transitionType?: TransitionType;
  initial?: string;
}

const Animation = ({
  variants,
  delay,
  exit,
  duration = defaultDuration,
  once,
  className,
  children,
  transitionType = TransitionType.Linear,
  initial = 'hidden',
}: IProps) => {
  const getTransitionType = (transitionType: TransitionType) => {
    switch (transitionType) {
      case TransitionType.Linear:
        return {};
      case TransitionType.Spring:
        return { type: 'spring' };
      case TransitionType.EaseIn:
        return { ease: 'easeIn' };
      case TransitionType.EaseOut:
        return { ease: 'easeOut' };
    }
  };
  return (
    <motion.div
      initial={initial}
      whileInView='visible'
      exit={`${exit ? 'hidden' : ''}`}
      viewport={{ once: once }}
      transition={{
        duration: duration,
        delay: delay,
        ...getTransitionType(transitionType),
      }}
      variants={variants}
      className={className}
    >
      {children}
    </motion.div>
  );
};
export default Animation;
