import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum Modal {
  None,
  Login,
  Register,
  Consulting,
  Language,
  ChangePassword,
  ForgotPassword,
  ResetPassword,
  Comparator,
}

export enum RegisterError {
  None,
  TakenEmailOrUsername,
  Other,
}

interface RegisterModalState {
  error: RegisterError;
}

interface ModalState {
  modal: Modal;
  animateModal: boolean;
  registerModal: RegisterModalState;
}

const initialState: ModalState = {
  modal: Modal.None,
  animateModal: true,
  registerModal: {
    error: RegisterError.None,
  },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, action: PayloadAction<Modal>) => {
      state.animateModal =
        state.modal === Modal.None || action.payload === Modal.None;
      state.modal = action.payload;
    },
    setRegisterModalError: (state, action: PayloadAction<RegisterError>) => {
      state.registerModal.error = action.payload;
    },
  },
});

export const { setModal, setRegisterModalError } = modalSlice.actions;

export default modalSlice.reducer;
