import React, { lazy, Suspense, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  Notification,
  setNotification,
} from '../../redux/slices/notificationSlice';
import TemplateAnimation, {
  AnimationTemplates,
} from '../Animations/TemplateAnimation';
import { XIcon } from '../Icons/UI/24/icons';
import s from './Notifications.module.scss';
import Loader from '../Loader/Loader';

// Lazy load notification components
const ChangeLanguage = lazy(() => import('./ChangeLanguage/ChangeLanguage'));
const InvalidLogin = lazy(() => import('./InvalidLogin/InvalidLogin'));
const Registration = lazy(() => import('./Registration/Registration'));
const RegistrationFailed = lazy(
  () => import('./RegistrationFailed/RegistrationFailed')
);
const LogOut = lazy(() => import('./LogOut/LogOut'));
const EndOfLife = lazy(() => import('./EndOfLife/EndOfLife'));
const AddDraft = lazy(() => import('./AddDraft/AddDraft'));
const AddWishlist = lazy(() => import('./AddWishlist/AddWishlist'));
const SaveDraft = lazy(() => import('./SaveDraft/SaveDraft'));
const SaveWishlist = lazy(() => import('./SaveWishlist/SaveWishlist'));
const FullAccess = lazy(() => import('./FullAccess/FullAccess'));
const RemoveConfiguration = lazy(
  () => import('./RemoveConfiguration/RemoveConfiguration')
);
const SaveToLater = lazy(() => import('./SaveToLater/SaveToLater'));
const OrderSent = lazy(() => import('./OrderSent/OrderSent'));
const Delete = lazy(() => import('./RemoveDraft/RemoveDraft'));
const ChangePassword = lazy(() => import('./ChangePassword/ChangePassword'));
const ForgotPassword = lazy(() => import('./ForgotPassword/ForgotPassword'));
const ContactSent = lazy(() => import('./ContactSent/ContactSent'));
const ContactFailed = lazy(() => import('./ContactFailed/ContactFailed'));
const ChangePasswordSuccess = lazy(
  () => import('./ChangePasswordSuccess/ChangePasswordSuccess')
);
const ChangePasswordFailed = lazy(
  () => import('./ChangePasswordFailed/ChangePasswordFailed')
);
const ForgotPasswordSuccess = lazy(
  () => import('./ForgotPasswordSuccess/ForgotPasswordSuccess')
);
const ForgotPasswordFailed = lazy(
  () => import('./ForgotPasswordFailed/ForgotPasswordFailed')
);
const ResetPasswordSuccess = lazy(
  () => import('./ResetPasswordSuccess/ResetPasswordSuccess')
);
const ResetPasswordFailed = lazy(
  () => import('./ResetPasswordFailed/ResetPasswordFailed')
);
const TooMuchItemsSelected = lazy(
  () => import('./TooMuchItemsSelected/TooMuchItemsSelected')
);
const InvalidUserUpdate = lazy(
  () => import('./InvalidUserUpdate/InvalidUserUpdate')
);
const UserUpdateSuccess = lazy(
  () => import('./UserUpdateSuccess/UserUpdateSuccess')
);
const Available = lazy(() => import('./Available/Available'));
const Discontinued = lazy(() => import('./Discontinued/Discontinued'));
const SoonAvailable = lazy(() => import('./SoonAvailable/SoonAvailable'));
const TechArea = lazy(() => import('./TechArea/TechArea'));

const Notifications = () => {
  const notification = useAppSelector(
    (state) => state.notification.notification
  );
  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = useState(false);

  const getContent = (notification: Notification) => {
    switch (notification) {
      case Notification.ChangeLanguage:
        return <ChangeLanguage setLoaded={setLoaded} />;
      case Notification.InvalidLogin:
        return <InvalidLogin setLoaded={setLoaded} />;
      case Notification.Registration:
        return <Registration setLoaded={setLoaded} />;
      case Notification.RegistrationFailed:
        return <RegistrationFailed setLoaded={setLoaded} />;
      case Notification.LogOut:
        return <LogOut setLoaded={setLoaded} />;
      case Notification.Available:
        return <Available setLoaded={setLoaded} />;
      case Notification.SoonAvailable:
        return <SoonAvailable setLoaded={setLoaded} />;
      case Notification.EndOfLife:
        return <EndOfLife setLoaded={setLoaded} />;
      case Notification.Discontinued:
        return <Discontinued setLoaded={setLoaded} />;
      case Notification.AddDraft:
        return <AddDraft setLoaded={setLoaded} />;
      case Notification.AddWishlist:
        return <AddWishlist setLoaded={setLoaded} />;
      case Notification.FullAccess:
        return <FullAccess setLoaded={setLoaded} />;
      case Notification.RemoveConfiguration:
        return <RemoveConfiguration setLoaded={setLoaded} />;
      case Notification.SaveToLater:
        return <SaveToLater setLoaded={setLoaded} />;
      case Notification.OrderSent:
        return <OrderSent setLoaded={setLoaded} />;
      case Notification.RemoveDraft:
        return <Delete setLoaded={setLoaded} />;
      case Notification.ChangePassword:
        return <ChangePassword setLoaded={setLoaded} />;
      case Notification.ForgotPassword:
        return <ForgotPassword setLoaded={setLoaded} />;
      case Notification.ContactSent:
        return <ContactSent setLoaded={setLoaded} />;
      case Notification.ContactFailed:
        return <ContactFailed setLoaded={setLoaded} />;
      case Notification.ChangePasswordSuccess:
        return <ChangePasswordSuccess setLoaded={setLoaded} />;
      case Notification.ChangePasswordFailed:
        return <ChangePasswordFailed setLoaded={setLoaded} />;
      case Notification.ForgotPasswordSuccess:
        return <ForgotPasswordSuccess setLoaded={setLoaded} />;
      case Notification.ForgotPasswordFailed:
        return <ForgotPasswordFailed setLoaded={setLoaded} />;
      case Notification.ResetPasswordSuccess:
        return <ResetPasswordSuccess setLoaded={setLoaded} />;
      case Notification.ResetPasswordFailed:
        return <ResetPasswordFailed setLoaded={setLoaded} />;
      case Notification.TooMuchItemsSelected:
        return <TooMuchItemsSelected setLoaded={setLoaded} />;
      case Notification.InvalidUserUpdate:
        return <InvalidUserUpdate setLoaded={setLoaded} />;
      case Notification.UserUpdateSuccess:
        return <UserUpdateSuccess setLoaded={setLoaded} />;
      case Notification.SaveDraft:
        return <SaveDraft setLoaded={setLoaded} />;
      case Notification.SaveWishlist:
        return <SaveWishlist setLoaded={setLoaded} />;
      case Notification.TechArea:
        return <TechArea setLoaded={setLoaded} />;
      default:
        return null;
    }
  };

  const allowClose =
    notification === Notification.InvalidLogin ||
    notification === Notification.AddDraft ||
    notification === Notification.RemoveConfiguration ||
    notification === Notification.FullAccess ||
    notification === Notification.TechArea ||
    notification === Notification.OrderSent ||
    notification === Notification.ContactSent;

  return (
    <AnimatePresence>
      {notification !== Notification.None && (
        <TemplateAnimation
          className={s.container}
          templates={[AnimationTemplates.Opacity]}
          once={true}
          exit={true}
        >
          <div className={s.contentWrapper}>
            <div className={s.background} />
            <div
              style={
                loaded
                  ? {}
                  : {
                      position: 'absolute',
                      visibility: 'hidden',
                    }
              }
              className={s.content}
            >
              {allowClose && (
                <div
                  onClick={() => dispatch(setNotification(Notification.None))}
                  className={s.close}
                >
                  <XIcon width='42px' height='42px' />
                </div>
              )}
              <Suspense fallback={<Loader />}>
                {getContent(notification)}
              </Suspense>
            </div>
            {!loaded && (
              <div className={s.loading}>
                <Loader />
              </div>
            )}
          </div>
        </TemplateAnimation>
      )}
    </AnimatePresence>
  );
};

export default Notifications;
