import { useEffect, useState } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import ReactGA from 'react-ga';
import withTranslation from '../../hoc/withTranslation';

const getLocale = (props: any) => props.language;

const HAWCCookieConsent = (props: any) => {
  const [userChoice, setUserChoice] = useState('');

  const cookiesTranslation = props.translation;

  useEffect(() => {
    const cookieChoice = Cookies.get('cookies-consent');
    if (cookieChoice === 'true') {
      initializeAnalytics();
    }
    setUserChoice(cookieChoice);
  }, []);

  const initializeAnalytics = () => {
    ReactGA.initialize('UA-179629806-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  };
  const onAccept = () => {
    setUserChoice('true');
    Cookies.set('cookies-consent', 'true', { expires: 150 });
    initializeAnalytics();
  };

  const onDecline = () => {
    setUserChoice('false');
    Cookies.set('cookies-consent', 'false', { expires: 150 });
  };
  return (
    <>
      {(userChoice === '' || !userChoice) && (
        <CookieConsent
          cookieName='cookies-consent'
          style={{ background: '#f5f7fa', border: '1px solid #212121' }}
          enableDeclineButton
          buttonText={props.translation.accept}
          declineButtonText={props.translation.decline}
          buttonStyle={{
            color: '#fefefe',
            background: '#0671e0',
            fontSize: '20px',
            padding: '10px',
            borderRadius: '8px',
          }}
          declineButtonStyle={{
            color: '#fefefe',
            fontSize: '20px',
            padding: '10px',
            borderRadius: '8px',
          }}
          expires={150}
          onAccept={onAccept}
          onDecline={onDecline}
        >
          <span style={{ fontSize: '18px', color: '#212121' }}>
            {cookiesTranslation.message}
          </span>
        </CookieConsent>
      )}
    </>
  );
};

export default withTranslation(
  'cookies-consent',
  'translations',
  getLocale
)(HAWCCookieConsent);
