import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface UserData {
  jwt: string;
  user: any;
}

interface UserState {
  loggedIn: boolean;
  isTechSpecialist: boolean;
  userData: UserData;
  rememberMe: boolean;
}

const initialState: UserState = {
  loggedIn: false,
  isTechSpecialist: false,
  userData: { jwt: '', user: {} },
  rememberMe: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setTechSpecialist: (state, action: PayloadAction<boolean>) => {
      state.isTechSpecialist = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
    },
    setRememberMe: (state, action: PayloadAction<boolean>) => {
      state.rememberMe = action.payload;
    },
    logOut: (state) => {
      state.loggedIn = false;
      state.isTechSpecialist = false;
      state.userData = { jwt: '', user: {} };
      state.rememberMe = false;
    },
  },
});

export const {
  setLoggedIn,
  setTechSpecialist,
  setUserData,
  setRememberMe,
  logOut,
} = userSlice.actions;

export default userSlice.reducer;
