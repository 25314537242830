import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IFilters } from '../../pages/ShopPage/components/Filters/Filters';

interface ShopPageSlice {
  nameFilter?: string;
  webFilters: IFilters;
  currentSortOption: string;
}

const initialState: ShopPageSlice = {
  webFilters: {
    sectors: {},
  },
  currentSortOption: 'price',
};

export const shopPageSlice = createSlice({
  name: 'shopPage',
  initialState,
  reducers: {
    setNameFilter: (state, action: PayloadAction<string>) => {
      state.nameFilter = action.payload;
    },
    setWebFilters: (state, action: PayloadAction<IFilters>) => {
      state.webFilters = action.payload;
    },
    setCurrentSortOption: (state, action: PayloadAction<string>) => {
      state.currentSortOption = action.payload;
    },
  },
});

export const { setNameFilter, setWebFilters, setCurrentSortOption } =
  shopPageSlice.actions;

export default shopPageSlice.reducer;
