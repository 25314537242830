import React, { useCallback, useEffect, lazy, Suspense } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Modal, setModal } from '../../redux/slices/modalSlice';
import {
  LoginError,
  setError as setLoginError,
} from '../../redux/slices/modals/loginModalSlice';
import {
  setError as setRegisterError,
  RegisterError,
} from '../../redux/slices/modals/registerModalSlice';
import {
  ConsultingType,
  setType,
} from '../../redux/slices/modals/consultingModalSlice';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { routeTranslations } from '../../App';
import { Language } from '../../utils';

// Lazy load the modal components
const LoginModal = lazy(() => import('./Modal/LoginModal/LoginModal'));
const RegisterModal = lazy(() => import('./Modal/RegisterModal/RegisterModal'));
const ConsultingModal = lazy(
  () => import('./Modal/ConsultingModal/ConsultingModal')
);
const LanguageModal = lazy(() => import('./Modal/LanguageModal/LanguageModal'));
const ChangePasswordModal = lazy(
  () => import('./Modal/ChangePasswordModal/ChangePasswordModal')
);
const ForgotPasswordModal = lazy(
  () => import('./Modal/ForgotPasswordModal/ForgotPasswordModal')
);
const ResetPasswordModal = lazy(
  () => import('./Modal/ResetPasswordModal/ResetPasswordModal')
);
const ComparatorModal = lazy(
  () => import('./Modal/ComparatorModal/ComparatorModal')
);

const Modals = () => {
  const currentLocation = useLocation();
  const dispatch = useAppDispatch();
  const modal: Modal = useAppSelector((state) => state.modal.modal);
  const animate: boolean = useAppSelector((state) => state.modal.animateModal);

  const currentLang =
    (window.location.pathname.split('/')[1] as Language) || 'en';

  // Get the translated routes for the current language
  const routes = routeTranslations[currentLang]; // Assume routeTranslations contains routes for each language

  useEffect(() => {
    // Dynamically check if the current path matches the translated "register" path
    const registerPath = routes.register; // Example of accessing the translated register path

    // Check if the current URL path is the translated "register" path
    if (currentLocation.pathname === `/${currentLang}/${registerPath}`) {
      dispatch(setModal(Modal.Register));
    }
  }, [currentLocation.pathname, dispatch, currentLang, routes.register]);

  const getModal = useCallback(
    (modal: Modal): React.ReactNode => {
      switch (modal) {
        case Modal.Login:
          return <LoginModal animate={animate} />;
        case Modal.Register:
          if (
            window.location.pathname !== `/${currentLang}/${routes.register}`
          ) {
            window.history.pushState(
              null,
              '',
              `/${currentLang}/${routes.register}`
            );
          }
          return <RegisterModal animate={animate} />;
        case Modal.Consulting:
          return <ConsultingModal animate={animate} />;
        case Modal.Language:
          return <LanguageModal animate={animate} />;
        case Modal.ChangePassword:
          return <ChangePasswordModal animate={animate} />;
        case Modal.ForgotPassword:
          return <ForgotPasswordModal animate={animate} />;
        case Modal.ResetPassword:
          return <ResetPasswordModal animate={animate} />;
        case Modal.Comparator:
          return <ComparatorModal animate={animate} />;
        default:
          return null;
      }
    },
    [animate, currentLang, routes.register]
  );

  useEffect(() => {
    if (modal === Modal.None) {
      // Revert URL back to the previous state when the modal is closed
      if (window.location.pathname === `/${currentLang}/${routes.register}`) {
        window.history.back(); // Or navigate to the desired URL
      }

      dispatch(setType(ConsultingType.None));
      dispatch(setLoginError(LoginError.None));
      dispatch(setRegisterError(RegisterError.None));
    }
  }, [modal, dispatch, currentLang, routes.register]);

  return (
    <AnimatePresence>
      {modal !== Modal.None && (
        <Suspense fallback={<div></div>}>{getModal(modal)}</Suspense>
      )}
    </AnimatePresence>
  );
};

export default Modals;
