import { Helmet } from 'react-helmet';
import withTranslation from '../../hoc/withTranslation';
import { useLocation } from 'react-router-dom';
import { routeTranslations } from '../../App'; // Import routeTranslations
import { Language } from '../../utils';

const SeoTags = (props: any) => {
  const location = useLocation();

  const currentLang =
    (window.location.pathname.split('/')[1] as Language) || 'en';

   // Get the translated routes for the current language
   const routes = routeTranslations[currentLang];

   // Construct the full URL dynamically based on the current language and pathname
   const currentFullUrl =
     `${process.env.REACT_APP_DOMAIN_URL}${location.pathname}`.replace(
       /\/$/,
       ''
     );

   // Adjust the regex for detecting a product page, making it language-agnostic
   const isProductPage = new RegExp(
     `/${currentLang}/${routes.categories}/[^/]+/[^/]+`
   ).test(currentFullUrl);

  // Find current page data based on the current full URL or fallback to the home page
  const currentPageData = isProductPage
    ? null
    : props?.translation?.pages?.find(
        (page: { url: string }) =>
          page.url.replace(/\/$/, '') === currentFullUrl
      ) ||
      props?.translation?.pages?.find(
        (page: { url: string }) =>
          page.url.replace(/\/$/, '') === process.env.REACT_APP_DOMAIN_URL
      );

  return props?.translationLoaded && currentPageData ? (
    <Helmet>
      <title>{currentPageData?.pageTitle}</title>
      <meta name='description' content={currentPageData?.description} />
      <meta name='keywords' content={props?.translation?.keywords} />
      {/* Additional meta tags can be added dynamically here */}
    </Helmet>
  ) : null;
};

export default withTranslation('seo')(SeoTags);
