import Animation, { TransitionType } from './Animation';
import React, { useCallback } from 'react';

export enum AnimationTemplates {
  Opacity,
  Scale,
  X,
  Y,
  Width,
  Height,
}

interface IProps {
  templates: Array<AnimationTemplates>;
  delay?: number;
  once: boolean;
  exit: boolean;
  duration?: number;
  className?: string;
  children: React.ReactNode;
  transitionType?: TransitionType;
  movement?: any;
  initial?: string;
}

const TemplateAnimation = ({
  templates,
  delay,
  once,
  exit,
  duration,
  className,
  children,
  transitionType,
  movement,
  initial,
}: IProps) => {
  const getVariants = useCallback(
    (templates: Array<AnimationTemplates>) => {
      let variants = {
        hidden: {},
        visible: {},
      };
      if (templates.includes(AnimationTemplates.Opacity)) {
        variants = {
          hidden: { ...variants.hidden, opacity: 0 },
          visible: { ...variants.visible, opacity: 1 },
        };
      }
      if (templates.includes(AnimationTemplates.Scale)) {
        variants = {
          hidden: { ...variants.hidden, scale: 0 },
          visible: { ...variants.visible, scale: 1 },
        };
      }
      if (templates.includes(AnimationTemplates.X)) {
        variants = {
          hidden: { ...variants.hidden, x: movement },
          visible: { ...variants.visible, x: 0 },
        };
      }
      if (templates.includes(AnimationTemplates.Y)) {
        variants = {
          hidden: { ...variants.hidden, y: movement },
          visible: { ...variants.visible, y: 0 },
        };
      }
      if (templates.includes(AnimationTemplates.Width)) {
        variants = {
          hidden: { ...variants.hidden, width: 0 },
          visible: { ...variants.visible, width: 'auto' },
        };
      }
      if (templates.includes(AnimationTemplates.Height)) {
        variants = {
          hidden: { ...variants.hidden, height: 0 },
          visible: { ...variants.visible, height: 'auto' },
        };
      }
      return variants;
    },
    [movement]
  );
  return (
    <Animation
      once={once}
      delay={delay}
      exit={exit}
      duration={duration}
      className={className}
      variants={getVariants(templates)}
      transitionType={transitionType}
      initial={initial}
    >
      {children}
    </Animation>
  );
};
export default TemplateAnimation;
