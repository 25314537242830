import { ReactComponent as AddUser } from './add-user.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as Brain1 } from './brain-1.svg';
import { ReactComponent as Burger } from './burger.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Camera } from './camera.svg';
import { ReactComponent as Category } from './category.svg';
import { ReactComponent as CheckSquare } from './check-square.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as Danger } from './danger.svg';
import { ReactComponent as Cart } from './cart.svg';
import { ReactComponent as DocumentDelite } from './document-delite.svg';
import { ReactComponent as Document } from './document.svg';
import { ReactComponent as Download2 } from './download-2.svg';
import { ReactComponent as Edit1 } from './edit-1.svg';
import { ReactComponent as Edit2 } from './edit-2.svg';
import { ReactComponent as EnvelopeEdit1 } from './envelope-edit-1.svg';
import { ReactComponent as ExpandFromCorner1 } from './expand-from-corner-1.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as Filter1 } from './filter-1.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as Hide } from './hide.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as Image } from './image.svg';
import { ReactComponent as InfoSquare } from './info-square.svg';
import { ReactComponent as Layers } from './layers.svg';
import { ReactComponent as Link2 } from './link-2.svg';
import { ReactComponent as Link } from './link.svg';
import { ReactComponent as Loading } from './loading.svg';
import { ReactComponent as Location } from './location.svg';
import { ReactComponent as LockCheck } from './lock-check.svg';
import { ReactComponent as LockOpen } from './lock-open.svg';
import { ReactComponent as LockX } from './lock-x.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as Login } from './login.svg';
import { ReactComponent as Logout1 } from './logout-1.svg';
import { ReactComponent as Logout } from './logout.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as Minus } from './minus.svg';
import { ReactComponent as MoreCircle } from './more-circle.svg';
import { ReactComponent as MoreSquare } from './more-square.svg';
import { ReactComponent as Paperclip } from './paperclip.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Save } from './save.svg';
import { ReactComponent as Scanner } from './scanner.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Send } from './send.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Share } from './share.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as VideoQuestion1 } from './video-question-1.svg';
import { ReactComponent as X } from './x.svg';

interface IProps {
  width?: string;
  height?: string;
  fill?: string;
  stroke?: string;
  className?: string;
  onClick?: any;
}

export const AddUserIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <AddUser
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const BellIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Bell
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Brain1Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Brain1
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const BurgerIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
  onClick,
}: IProps) => (
  <Burger
    className={className}
    onClick={onClick}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const CalendarIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Calendar
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const CameraIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Camera
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const CategoryIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Category
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const CheckSquareIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <CheckSquare
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const CheckIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Check
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const CloseIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
  onClick,
}: IProps) => (
  <Close
    onClick={onClick}
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const CopyIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Copy
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const DangerIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Danger
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const CartIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Cart
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);


export const DocumentDeliteIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <DocumentDelite
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const DocumentIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Document
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Download2Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Download2
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Edit1Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Edit1
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Edit2Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Edit2
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const EnvelopeEdit1Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <EnvelopeEdit1
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const ExpandFromCorner1Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <ExpandFromCorner1
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const EyeIcon = ({ width, height, fill, stroke, className }: IProps) => (
  <Eye
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Filter1Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Filter1
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const FilterIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Filter
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const HideIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Hide
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const HomeIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Home
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const ImageIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Image
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const InfoSquareIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <InfoSquare
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LayersIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Layers
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Link2Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Link2
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LinkIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Link
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LoadingIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Loading
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LocationIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Location
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LockCheckIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <LockCheck
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LockOpenIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <LockOpen
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LockXIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <LockX
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LockIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Lock
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LoginIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Login
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const Logout1Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Logout1
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const LogoutIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Logout
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const MailIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Mail
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const MinusIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Minus
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const MoreCircleIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <MoreCircle
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const MoreSquareIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <MoreSquare
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const PaperclipIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Paperclip
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const PlusIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Plus
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const SaveIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Save
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const ScannerIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Scanner
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const SearchIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Search
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const SendIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Send
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const SettingsIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Settings
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const ShareIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Share
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const TrashIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Trash
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const UserIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <User
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const UsersIcon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <Users
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const VideoQuestion1Icon = ({
  width,
  height,
  fill,
  stroke,
  className,
}: IProps) => (
  <VideoQuestion1
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);

export const XIcon = ({
  width,
  height,
  fill,
  stroke = '#717171',
  className,
}: IProps) => (
  <X
    className={className}
    style={{
      width: width,
      height: height,
      fill: fill,
      stroke: stroke,
    }}
  />
);
