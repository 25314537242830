import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum ConsultingType {
  None,
  Sales = 'Sales Support',
  TechSupport = 'Tech Support',
  RMA = 'RMA',
}

interface ConsultingModalState {
  type: ConsultingType;
}

const initialState: ConsultingModalState = {
  type: ConsultingType.None,
};

export const consultingModalSlice = createSlice({
  name: 'consultingModal',
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<ConsultingType>) => {
      state.type = action.payload;
    },
  },
});

export const { setType } = consultingModalSlice.actions;

export default consultingModalSlice.reducer;
