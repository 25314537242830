import React, { useCallback, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import TemplateAnimation, {
  AnimationTemplates,
} from '../Animations/TemplateAnimation';
import s from './Button.module.scss';
import { useMediaQuery } from 'usehooks-ts';

export enum Style {
  Primary = s.primary,
  Secondary = s.secondary,
  Tertiary = s.tertiary,
  Orange = s.orange,
  BlackOutline = s.blackOutline,
  BlackOutlineSectors = s.blackOutlineSectors,
  Gray = s.gray,
  Green = s.green,
  Sale = s.sale,
  Azure = s.azure,
  Navy = s.navy,
  Error = s.error,
  Archive = s.archive,
}

export enum Size {
  ExtraBig = s.extraBigSize,
  Bigger = s.bigger,
  Big = s.bigSize,
  Normal = s.normalSize,
  Medium = s.mediumSize,
  Small = s.smallSize,
}

export enum IconPlacement {
  Left,
  Right,
}

interface IProps {
  style: Style;
  size: Size;
  children: React.ReactNode;
  width?: string;
  height?: string;
  mobileHeight?: string;
  selected?: boolean;
  minimalContentHeight?: string;
  icon?: React.ReactNode;
  iconHover?: React.ReactNode;
  iconPlacement?: IconPlacement;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  customFontSize?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  title?: string;
}

const Button = ({
  children,
  style,
  size,
  width,
  height = '56px',
  mobileHeight = '40px',
  selected = false,
  minimalContentHeight,
  icon,
  iconHover,
  iconPlacement,
  disabled = false,
  onClick,
  customFontSize = false,
  className,
  type,
}: IProps) => {
  const [hovering, setHovering] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const getIcon = useCallback(
    (
      hover: boolean,
      icon: React.ReactNode,
      iconHover: React.ReactNode = null
    ): React.ReactNode => {
      if (iconHover === null) {
        return <div className={s.iconWrapper}>{icon}</div>;
      }
      return <div className={s.iconWrapper}>{hover ? iconHover : icon}</div>;
    },
    []
  );
  const buttonContent = (
    <span
      style={{ display: 'flex', minHeight: minimalContentHeight }}
      className={s.contentWrapper}
    >
      {children}
    </span>
  );
  const buttonIcon =
    iconHover && !icon ? (
      <>
        <AnimatePresence>
          {hovering && (
            <TemplateAnimation
              className={s.buttonAnimation}
              templates={[AnimationTemplates.Opacity, AnimationTemplates.Width]}
              once={true}
              exit={true}
            >
              {getIcon(hovering, icon, iconHover)}
            </TemplateAnimation>
          )}
        </AnimatePresence>
      </>
    ) : (
      getIcon(hovering, icon, iconHover)
    );
  return (
    <button
      type={type}
      style={{ width: width, height: isMobile ? mobileHeight : height }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      disabled={disabled}
      className={`${
        s.button
      } ${className} ${style.valueOf()} ${size.valueOf()} ${
        selected ? `shadow-2 ${s.selected}` : ''
      }`}
      onClick={onClick}
    >
      {iconPlacement === IconPlacement.Left && buttonIcon}
      {customFontSize ? (
        <>{buttonContent}</>
      ) : (
        <p className='medium'>{buttonContent}</p>
      )}
      {iconPlacement === IconPlacement.Right && buttonIcon}
    </button>
  );
};
export default Button;
