import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum UserUpdateError {
  None,
  CompanyNameTaken,
}

interface DashboardState {
  userUpdateError: UserUpdateError;
}

const initialState: DashboardState = {
  userUpdateError: UserUpdateError.None,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setUserUpdateError: (state, action: PayloadAction<UserUpdateError>) => {
      state.userUpdateError = action.payload;
    },
  },
});

export const { setUserUpdateError } = dashboardSlice.actions;

export default dashboardSlice.reducer;
